import styled from 'styled-components';

import vars from 'variables';

import search from 'assets/FAQs/search.svg';
import warning from 'assets/FAQs/warning.svg';

const Root = styled.div`
  .search {
    padding-top: 27px;
    text-align: center;
  }

  input {
    color: ${vars.colors.white};
    font-weight: bold;
    font-family: ${vars.fonts.default};
    text-transform: uppercase;
    background: rgb(13, 16, 35) url(${search}) no-repeat center right 16px;
    border: 2px solid rgb(64, 66, 81);
    border-radius: 10px;
  }

  .not-found {
    padding: 70px 0;
    display: none;
    font-weight: bold;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
    background: url(${warning}) no-repeat center top 41px;
  }

  .no-results .not-found {
    display: block;
  }

  .not-found strong {
    color: white;
    display: block;
  }

  .not-found span {
    color: rgb(64, 66, 81);
  }

  ${vars.desktop.mediaQuery} {
    input {
      width: 350px;
      padding: 21px 18px;
      font-size: 0.775rem;
    }

    .not-found strong {
      font-size: 0.8rem;
    }

    .not-found span {
      font-size: 0.75rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    input {
      width: calc(100vw - 80px);
      padding: 17px 18px 16px;
      font-size: 0.65rem;
    }

    .not-found strong {
      font-size: 0.6rem;
    }

    .not-found span {
      font-size: 0.55rem;
    }
  }
`;

export { Root };
