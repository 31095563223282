import React from 'react';
import { Root } from './style';

const Categories = ({ categories, state }) => {
  return (
    <Root>
      <ul>
      {categories.map(category =>
        <li key={category}
            className={state.category.get() === category ? 'active' : ''}
            onClick={() => state.category.set(category)}>
          {category}
        </li>
      )}
      </ul>
    </Root>
  );
};

export default Categories;
