import React, { useRef } from "react";
import { Root } from "./style";

import { useSiteData } from "utils";
import { useState } from "@hookstate/core";
import Mark from "mark.js";

import PageHeader from "components/Common/PageHeader";
import Categories from "./Categories";
import Questions from "./Questions";

const FAQs = () => {
  const {
    faqs: { faqs },
  } = useSiteData().pages;

  const questions = {};

  let id = 0,
    currentCategory = "General",
    currentQuestion;

  faqs.split(/\n/).forEach((line) => {
    const category = line.match(/(?:=== )(.*)/);

    if (category) {
      currentCategory = category[1];
      currentQuestion = null;
    } else if (line.match(/^\s*---\s*$/)) {
      currentQuestion = null;
    } else if (!currentQuestion) {
      currentQuestion = { id: id++, question: line, answer: "" };
      questions[currentCategory] || (questions[currentCategory] = []);
      questions[currentCategory].push(currentQuestion);
    } else {
      currentQuestion.answer += (currentQuestion.answer ? "\n" : "") + line;
    }
  });

  Object.keys(questions).forEach((category) => {
    if (!questions[category].length) {
      delete questions[category];
    }
  });

  const categories = Object.keys(questions);

  const el = useRef();
  let marker;

  const state = useState({
    search: null,
    category: categories[0],
    question: questions[categories[0]][0].id,
  });

  state.attach(() => ({
    init: () => ({
      onSet: ({ path, value }) => {
        switch (path[0]) {
          case "category":
            state.question.set(questions[value][0].id);
            break;
          case "search":
            state.question.set(-1);
            if (value) {
              marker || (marker = new Mark(el.current));
              marker.unmark({
                done: () =>
                  marker.mark(value, {
                    separateWordSearch: false,
                    done: () => {
                      el.current
                        .querySelectorAll("li")
                        .forEach((el) => el.setAttribute("class", "unmarked"));
                      el.current
                        .querySelectorAll("mark")
                        .forEach((el) =>
                          el.closest("li").setAttribute("class", "active")
                        );
                      el.current.parentNode.setAttribute(
                        "class",
                        el.current.querySelectorAll("mark").length
                          ? ""
                          : "no-results"
                      );
                    },
                  }),
              });
            } else {
              marker.unmark();
              el.current
                .querySelectorAll("li")
                .forEach((el) => el.setAttribute("class", ""));
              el.current.parentNode.setAttribute("class", "");
              state.question.set(questions[state.category.get()][0].id);
            }
            break;
          default:
        }
      },
    }),
  }));

  return (
    <Root className="body-width">
      <PageHeader page="faqs" />
      <div className="search">
        <input
          type="text"
          placeholder="SEARCH"
          onChange={(e) => state.search.set(e.target.value)}
        />
      </div>
      <Categories categories={categories} state={state} />
      <div>
        <div className="not-found">
          <strong>No results for &quot;{state.search.get()}&quot;</strong>
          <span>Try search for something different</span>
        </div>
        <div ref={el}>
          {categories.map((category) => (
            <Questions
              key={category}
              questions={questions[category]}
              category={category}
              state={state}
            />
          ))}
        </div>
      </div>
    </Root>
  );
};

export default FAQs;
