import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  text-align: center;

  ul, li {
    display: inline-block;
    border-radius: 25px;
  }

  ul {
    margin: 30px 0;
    padding: 3px;
  }

  li {
    color: rgb(93, 113, 168);
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
  }

  li.active {
    color: white;
    cursor: default;
    background: ${vars.colors.blue};
  }

  ${vars.desktop.mediaQuery} {
    ul {
      margin-bottom: 40px;
      background: rgb(0, 42, 84);
    }

    li {
      margin-right: -13px;
      padding: 0 25px;
      font-size: 0.85rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    li {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0 12px;
      font-size: 0.75rem;
      line-height: 200%;
      background: rgb(0, 42, 84);
    }
  }
`;

export { Root };
