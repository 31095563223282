import React from 'react';
import { Root } from './style';

const Questions = ({ questions, category, state }) => {
  return (
    <Root className={state.category.get() === category ? 'active' : ''}>
      <ul>
      {questions.map((question, index) =>
        <li key={index}
            className={state.question.get() === question.id ? 'active' : ''}
            onClick={() => state.question.set(question.id)}>
          <strong>{question.question}</strong>
          <p>{question.answer}</p>
        </li>
      )}
      </ul>
    </Root>
  );
};

export default Questions;
